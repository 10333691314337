/*
	This font software is the property of Commercial Type.

	You may not modify the font software, use it on another website, or install it on a computer.

	License information is available at http://commercialtype.com/eula
	For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

	Copyright (C) 2016 Schwartzco Inc.
	
*/

@font-face {
  font-family: "Action NBA Web";
  src: url("./fonts/ActionNBA-Light-Web.woff2") format("woff2"),
    url("./fonts/ActionNBA-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Action NBA Web";
  src: url("./fonts/ActionNBA-LightItalic-Web.woff2") format("woff2"),
    url("./fonts/ActionNBA-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Action NBA Web";
  src: url("./fonts/ActionNBA-Medium-Web.woff2") format("woff2"),
    url("./fonts/ActionNBA-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Action NBA Web";
  src: url("./fonts/ActionNBA-MediumItalic-Web.woff2") format("woff2"),
    url("./fonts/ActionNBA-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Action NBA Web";
  src: url("./fonts/ActionNBA-Bold-Web.woff2") format("woff2"),
    url("./fonts/ActionNBA-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Action NBA Web";
  src: url("./fonts/ActionNBA-BoldItalic-Web.woff2") format("woff2"),
    url("./fonts/ActionNBA-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
