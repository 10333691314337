.ism-nav__tab {
  display: inline-block;
  padding: 1.5rem 2rem;
  border-radius: 3px 3px 0 0;
  background-color: white;
  color: black;
  font-size: 1.3rem;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .ism-nav__tab {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.ism-nav__tab.active {
  background-color: #eabd0c;
  color: black;
}
