.dropdown {
  display: inline-block;
  position: relative;
}

.list {
  position: absolute;
  visibility: hidden;
  transform: scale(0);
  top: 100%;
  width: 18rem;
  transition: 0.1s;
  border-radius: 0 0 3px 3px;
  background-color: white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  z-index: 100;
  -webkit-transform-origin: top right;
  transform-origin: top right;

  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

[dir="rtl"].list {
  left: 0;
}
[dir="ltr"].list {
  right: 0;
}

.list.show {
  visibility: visible;
  transform: scale(1);
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
